import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable, tap} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private httpHeaders = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
    })
  };

  constructor(private http:HttpClient) { }

  /**
   * Get request
   *
   * @param url
   */
  public get(url:string): Observable<any>{
    return this.http.get(url, this.httpHeaders).pipe(
      tap({
        error: error => {
          if (error.status === 500) {
            // Handle 500
          } else if (error.status === 400) {
            // Handle 400
          } else if (error.status === 401) {
            // Handle 401
          }
        }}));
  }

  /**
   * Post request
   *
   * @param url
   * @param data
   */
  public post(url:string, data:any=[]):Observable<any>{
    return this.http.post(url, data, this.httpHeaders)
  }

  /**
   * Post request
   *
   * @param url
   * @param data
   */
  public patch(url:string, data:any=[]):Observable<any>{
    return this.http.patch(url, data, this.httpHeaders)
  }

  /**
   * Put request
   *
   * @param url
   * @param data
   * @param headers
   */
  public put(url:string, data:any=[], headers:{}):Observable<any>  {
    return this.http.put(url, data, headers)
  }

  /**
   * Delete request
   *
   * @param url
   * @param data
   * @param headers
   */
  public delete(url:string):Observable<any>  {
    return this.http.delete(url)
  }

}
