import {Component, Input} from '@angular/core';
import {NgIf} from "@angular/common";

@Component({
  selector: 'loader-component',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  imports: [
    NgIf
  ],
  standalone: true
})
export class LoaderComponent {
  @Input ('loading-text') loadingText:string|null  = null
}
