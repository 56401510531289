import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, ParamMap, Params, Router} from "@angular/router";
import {AuthenticateService} from "../../services/authentication/authenticate.service";
import {environment} from "../../../environments/environment";
import {Subscription} from "rxjs";
import {TokenService} from "../../services/authentication/token.service";

@Component({
  selector: 'app-portal',
  templateUrl: './portal.component.html',
  styleUrls: ['./portal.component.scss']
})
export class PortalComponent implements OnInit, OnDestroy {

  public loading:boolean = false;

  public env = environment.title;
  private token:string|null = null;
  constructor(private activatedRoute:ActivatedRoute, private auth:AuthenticateService, private router:Router, private tokenService:TokenService) {}

  private authSubscription?:Subscription;

  ngOnInit() {
    if(this.activatedRoute.snapshot.params.hasOwnProperty('vehicle')) {
      localStorage.setItem('bookmymot-vehicle-id', this.activatedRoute.snapshot.params['vehicle'])
    }
    // checking if the token is in the url ...
    if(this.activatedRoute.snapshot.params.hasOwnProperty('token')) {
      this.token = this.activatedRoute.snapshot.params['token']
      this.tokenService.setToken(this.token)
    }
    this.authSubscription = this.auth.attemptLogin(null, null).subscribe((response:any)=>{
      if(!response){
        setTimeout(()=>{this.router.navigateByUrl('/login')}, environment.loadingTimes.portal)
      }else{
        setTimeout(()=>{this.router.navigateByUrl('/dashboard')}, environment.loadingTimes.portal)
      }
    }, error => {
      this.router.navigateByUrl('/login')
    })
  }


  ngOnDestroy() {
    this.authSubscription?.unsubscribe()
  }
}
