import {environment} from "../../environments/environment";

/**
 * @method getBookings()
 */
export class ApiRoutes {

  private static base:string = environment.apiURL

  private static authenticate = {
    baseAuth:'authenticate',
    login:'login',
    loginWithEmail:'login-with-email',
    byToken:'auth-by-token',
    checkToken:'check-token',
  }

  private static client = {
    //get request
    vehicles:`${this.base}/client/vehicles`,
    garages:`${this.base}/client/garages`,
    google: {
      base:`${this.base}/client/google`,
      endpoints:{
        storage:'storage-url',
        historyStorage:'history-media-url',
        media:'media',
        makePublic:'make-image-public',
        delete:'delete-object'
      }
    },
    booking:{
      base:`${this.base}/client/bookings`,
      endpoints:{
        create:'create',
        getBookings:'index'
      }
    },
    history:{
      base:`${this.base}/client/history`,
      endpoints:{
        create:'',
        getHistory:'',
        delete:''
      }
    },
    client: {
      base:`${this.base}/client`,
      endpoints:{
        update:'update',
        delete:'delete'
      }
    }
  }


  public static login():string {
    return `${this.base}/${this.authenticate.baseAuth}/${this.authenticate.login}`
  }

  public static byToken():string {
    return `${this.base}/${this.authenticate.baseAuth}/${this.authenticate.byToken}`;
  }

  public static checkToken():string {
    return `${this.base}/${this.authenticate.baseAuth}/${this.authenticate.checkToken}`;
  }

  public static loginWithEmail() :string {
    return `${this.base}/${this.authenticate.baseAuth}/${this.authenticate.loginWithEmail}`;
  }

  public static getVehicles() :string {
    return this.client.vehicles
  }

  public static createVehicle() :string {
    return this.client.vehicles
  }

  public static deleteVehicle():string {
    return this.client.vehicles
  }

  public static getGarages() :string {
    return this.client.garages
  }

  public static testRequest() :string {
    return `${this.base}/user`;
  }


  public static googleStorageUrl() :string {
    return `${this.client.google.base}/${this.client.google.endpoints.storage}`
  }

  public static googleStorageUrlForHistory() :string {
    return `${this.client.google.base}/${this.client.google.endpoints.historyStorage}`
  }

  public static getGoogleMedia() :string {
    return `${this.client.google.base}/${this.client.google.endpoints.media}`
  }

  public static makeGoogleImagePublic() :string {
    return `${this.client.google.base}/${this.client.google.endpoints.makePublic}`
  }

  public static deleteGoogleImage() :string {
    return `${this.client.google.base}/${this.client.google.endpoints.delete}`
  }

  public static createBooking():string {
      return `${this.client.booking.base}/${this.client.booking.endpoints.create}`
  }

  public static getBookings():string {
      return `${this.client.booking.base}/${this.client.booking.endpoints.getBookings}`
  }

  public static getHistory():string {
    return `${this.client.history.base}/${this.client.history.endpoints.getHistory}`
  }

  public static createHistory():string {
    return `${this.client.history.base}/${this.client.history.endpoints.create}`
  }

  public static deleteHistory():string {
    return `${this.client.history.base}/${this.client.history.endpoints.delete}`
  }

  public static updateClient():string {
    return `${this.client.client.base}/${this.client.client.endpoints.update}`
  }

  public static deleteClientAccount():string {
    return `${this.client.client.base}/${this.client.client.endpoints.delete}`
  }
}
