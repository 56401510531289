import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import {map, Observable, tap} from 'rxjs';
import {AuthenticateService} from "../services/authentication/authenticate.service";

@Injectable({
  providedIn: 'root'
})
export class IsAuthenticatedGuard  {

  constructor(private authenticate:AuthenticateService, private router:Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.authenticate.isLoggedIn.pipe(
      map((isLoggedIn:boolean)=>{
        if(isLoggedIn) {
          return true
        }else{
          this.router.navigateByUrl('/portal')
          return false
        }
      })
    );
  }

}
