import { Component } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ApiService} from "../../services/http/api.service";
import {ApiRoutes} from "../../api/routes";
import {AuthenticateService, LoginDetails} from "../../services/authentication/authenticate.service";
import {Router} from "@angular/router";
import {DateTime} from "luxon";
import {environment} from "../../../environments/environment.local";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

  public now = DateTime.now().toFormat('yyyy')

  public loginFailed:boolean = false;

  public unregisteredUser:boolean = false
  public success:boolean = false;

  public loading:boolean=false;

  public fieldPassword:boolean = true;
  public missingPassword:boolean = false;
  public fieldError:boolean = false;
  public loginForm:FormGroup = this.fb.group({
    user:['', Validators.required],
    password:['', Validators.required]
  })

  constructor(private fb:FormBuilder, private api:ApiService, private auth:AuthenticateService, private router:Router) {}

  public attemptLogin() {
    if(this.missingPassword) {
      if(this.loginForm.get('user')?.valid){
        this.loading = true
        setTimeout(()=>{
          this.api.post(ApiRoutes.loginWithEmail(), {user:this.loginForm.get('user')?.value}).subscribe(response=>{
            this.success = true
          }, ()=>{this.unregisteredUser = true, this.loading=false}, ()=>this.loading = false)
        }, 3000)
      }else{
        this.fieldError = true
        this.loading=false
      }
    }else{
      if(this.loginForm.invalid) {
        this.fieldError = true
      }else{
        this.loading=true

        //@ts-ignore
        let details:LoginDetails = {username:this.loginForm.get('user')?.value, password:this.loginForm.get('password')?.value}
        setTimeout(()=>{
          this.auth.loginByUserAndPass(details).subscribe(response=>{
          if(response){

            this.loading = false
            this.router.navigateByUrl('/dashboard')
          }else{
            this.loading = false
            this.authFailed()
          }
        }, (error)=>{
          this.loading = false
          this.authFailed()
        })
        }, 3000)

      }
    }
  }


  private authFailed() {
    this.loginFailed = true
    setTimeout(()=>{
      this.loginFailed = false
    }, 4000)
  }

  public registerHere() {
    location.replace(environment.websiteUrl)
  }

}
