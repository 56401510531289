import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {LoginComponent} from "./authentication/login/login.component";
import {IsAuthenticatedGuard} from "./guards/is-authenticated.guard";
import {PortalComponent} from "./authentication/portal/portal.component";

const routes: Routes = [
  {
    path:'',
    pathMatch:'full',
    redirectTo:'portal'
  },

  {
    path:'token/:token/:vehicle',
    redirectTo:'portal/:token/:vehicle'
  },

  {
    path:'token/:token',
    redirectTo:'portal/:token'
  },

  {
    path:'portal/:token',
    component:PortalComponent
  },

  {
    path:'portal/:token/:vehicle',
    component:PortalComponent
  },

  {
    path:'portal',
    component:PortalComponent
  },

  {
    path:'login',
    component:LoginComponent
  },

  {
    path:'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate:[IsAuthenticatedGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
