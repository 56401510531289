import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  private tokenKey = 'bookmymot-token'
  constructor() { }

  public getToken():string|null {
    return localStorage.getItem(this.tokenKey)
  }

  /**
   * Checks if the token is there.
   * @private
   */
  public hasToken():boolean {
    return !!this.getToken()
  }

  /**
   * Compares a token with another one from storage
   * @param token
   */
  public isTheSame(token:string):boolean {
    if(this.hasToken()) {
        return this.getToken() == token
    }
    return false
  }

  setToken(token:string|null) {
    if(token){
      localStorage.setItem(this.tokenKey, token)
    }
  }

  deleteToken() {
    localStorage.removeItem(this.tokenKey)
  }
}
