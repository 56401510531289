import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
import {UserModel} from "../../models/user.model";


@Injectable({
  providedIn: 'root'
})
export class UserDetailsService {

  private details = new BehaviorSubject({})

  constructor() { }

  public getDetails() {
    return this.details as Observable<any>
  }

  public addDetails(details:UserModel) {
    this.details.next(details)
  }
}
