import { Injectable } from '@angular/core';
import {BehaviorSubject, map, Observable, of, tap} from "rxjs";
import {TokenService} from "./token.service";
import {ApiRoutes} from "../../api/routes";
import {ApiService} from "../http/api.service";
import {UserDetailsService} from "../user/user-details.service";

export interface LoginDetails {
  user:string | null,
  password:string | null
}
@Injectable({
  providedIn: 'root'
})
export class AuthenticateService {

  private isLoggedInSubject = new BehaviorSubject(false)
  public isLoggedIn = this.isLoggedInSubject as Observable<boolean>
  constructor(private tokenService:TokenService, private api:ApiService, private userService:UserDetailsService) { }


  public attemptLogin(details:LoginDetails|null, token:string|null):Observable<boolean> {
    if(details) {
      return  this.loginByUserAndPass(details)
    }else if(token) {
      return this.attemptLoginByToken(token)
    }else{
      return this.checkForToken()
    }
  }

  public loginByUserAndPass( details: LoginDetails ):Observable<boolean> {

    return this.api.post(ApiRoutes.login(), details).pipe(map((response:any)=>{
      if(response.hasOwnProperty('authenticated')) {
        this.tokenService.setToken(response.data.auth_token)
        this.userService.addDetails(response.data)
        this.isLoggedInSubject.next(true)
        return true
      }else{
       return false
      }
    }))
  }

  public attemptLoginByToken(token:string):Observable<boolean> {

    //check if the token is valid ( send request in the backend )
    return this.api.post(ApiRoutes.checkToken(), {token} ).pipe(map((response:any)=>{
      if(response.hasOwnProperty('data')) {
        this.userService.addDetails(response.data)
        this.isLoggedInSubject.next(true)
        return true
      }else{
        this.isLoggedInSubject.next(false)
        return false
      }
    }))
  }

  //This checks for token if the user comes to the dashboard without token in the url
  private checkForToken():Observable<boolean> {
    let token = this.tokenService.getToken()
    if(this.tokenService.hasToken() && token){
      return this.attemptLoginByToken(token)
    }else{
      return of(false)
    }
  }
}
